.demo-image-preview {
    position: relative;
    text-align: center;
  }
  
  .demo-image-preview > img {
    width: 100%;
  }
  
  @media(max-width:768px){
    .demo-image-preview > img {
      width: 100%;
    }
  }
  
  /* fullscreen enable by props */
  .demo-image-preview-fullscreen > img {
    width: 100vw;
    height:100vh;
  }